import { ButtonColor, PrimaryButton } from 'component-library/components/buttons/PrimaryButton';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { track } from 'utils/analytics';
import { PartnershipPageChildProps } from '../shared/types';
import { ageOptions, defaultBodyText, defaultCtaText, defaultHeadingText, maritalStatusOptions, savingsOptions, timeToRetirementOptions } from './content';
import { WordSelector } from './WordSelector';

export interface RetirementHorizonFormProps extends PartnershipPageChildProps {}

const Wrapper = styled(Flex)`
    margin: 64px 16px;
    padding: 16px;
    background-color: ${colors.white};
    border-radius: 32px;
    border-top: 1px solid ${colors.black};
    border-right: 1px solid ${colors.black};
    border-bottom: 3px solid ${colors.black};
    border-left: 3px solid ${colors.black};

    @media ${device.lg} {
        max-width: 1000px;
        margin: 40px auto;
        padding: 40px 0;
    }
`;

const StyledHeading = styled(Heading)`
    text-align: center;
`;

const SubmitButton = styled(PrimaryButton)`
    margin-top: 32px;
    max-width: 420px;
`;

export const RetirementHorizonForm: FC<RetirementHorizonFormProps> = ({ onCtaClick }) => {
    const [age, setAge] = useState('');
    const [timeToRetirement, setTimeToRetirement] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [savings, setSavings] = useState('');

    const handleSubmit = () => {
        track('Retirement Horizon Form Submitted', { age, timeToRetirement, maritalStatus, savings });
        onCtaClick();
    };

    return (
        <Wrapper column alignItems="center" justifyContent="flex-start">
            <StyledHeading variant={4}>
              {defaultHeadingText}
            </StyledHeading>
            <Body variant={3}>
              {defaultBodyText}
            </Body>
            <Flex column marginTop={40}>
                <Body variant={1} marginBottom={16}>
                    I am <WordSelector value={age} options={ageOptions} onChange={setAge} /> years old and want to retire in <WordSelector value={timeToRetirement} options={timeToRetirementOptions} onChange={setTimeToRetirement} />.
                </Body>
                <Body variant={1}>
                    I am <WordSelector value={maritalStatus} options={maritalStatusOptions} onChange={setMaritalStatus} /> and have saved <WordSelector value={savings} options={savingsOptions} onChange={setSavings} /> for retirement.
                </Body>
            </Flex>
            <SubmitButton
              fullWidth
              buttonColor={ButtonColor.Teal}
              alignment="center"
              onClick={handleSubmit}
            >
              {defaultCtaText}
            </SubmitButton>
        </Wrapper>
    );
}
