import React, { FC } from 'react';
import { Step } from './Step';
import { StaticImage } from 'gatsby-plugin-image';

interface ProtectStepProps {}

export const ProtectStep: FC<ProtectStepProps> = () => {
  return (
    <Step
      heading="$1M in identity theft protection with identity and credit monitoring"
      body="$1 million in identity theft insurance — along with real human assistance, white-glove credit freezing, lost wallet assistance, and the ability to block spam calls."
      image={<StaticImage src="../images/protect-step.webp" alt="protect" />}
    />
  );
}
