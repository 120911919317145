import React, { FC } from 'react';
import { Step } from './Step';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { getCurrentInterestRateDetails } from 'common/interest-rate-components/base';

interface SaveStepProps {}

export const SaveStep: FC<SaveStepProps> = () => {
  const data = useStaticQuery(graphql`
    query SpendSaveProtectPlan {
      ...GetCurrentInterestRateDetails
    }
  `);
  const { formattedApy } = getCurrentInterestRateDetails(data);

  return (
    <Step
      heading={`High-yield deposit account earning ${formattedApy} APY* on your cash savings`}
      body="Savings and budgetary insights maximize your cash flows while monthly cash sweeps help you have more cash to spend in retirement."
      image={<StaticImage src="../images/save-step.webp" alt="save" />}
    />
  );
}
