import React, { FC } from 'react';
import { Step } from './Step';
import { StaticImage } from 'gatsby-plugin-image';

interface PlanStepProps {}

export const PlanStep: FC<PlanStepProps> = () => {
  return (
    <Step
      heading="One-on-one retirement plan guidance and monitoring"
      body="Get a personalized retirement plan with the ongoing guidance and monitoring of a dedicated fiduciary advisor."
      image={<StaticImage src="../images/plan-step.webp" alt="plan" />}
    />
  );
}
