import { Flex } from 'component-library/components/layout/Flex';
import { Modal, ModalBody } from 'component-library/components/modals/Modal';
import { SubheadingText } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ConsultationForm } from '../consultation-form';
import { defaultInitialValues } from '../consultation-form/formUtils';
import { StaticImage } from 'gatsby-plugin-image';
import { PartnershipPageChildProps } from '../shared/types';

const Subheading = styled(SubheadingText)`
  margin: 24px 0;
`;

interface ConsultationModalProps extends PartnershipPageChildProps {
  modalImg?: React.ReactNode;
}

export const MODAL_ID = 'consultation-modal';

const DefaultRetirableLogo = () => (
  <StaticImage
    src="../images/retirable-logo.webp"
    alt="partnership logo"
    width={512}
  />
);

export const ConsultationModal: FC<ConsultationModalProps> = ({
    modalImg = <DefaultRetirableLogo />,
    defaultUtmParams,
    onConsultationClose,
}) => (
  <Modal
    modalId={MODAL_ID}
    wide
    fullHeight={false}
    backgroundColor={colors.teal}
    onClose={onConsultationClose}
  >
    <ModalBody>
      <Flex column justifyContent="center" alignItems="center">
         {modalImg}
         <Subheading variant={2}>
          Talk to a licensed Retirable Advisor today!
        </Subheading>
        <ConsultationForm
            initialValues={defaultInitialValues}
            submitButtonText="Meet with a Retirable Advisor"
            modalId={MODAL_ID}
            defaultUtmParams={defaultUtmParams}
        />
      </Flex>
    </ModalBody>
  </Modal>
);
