import React, { FC } from 'react';
import { Step } from './Step';
import { StaticImage } from 'gatsby-plugin-image';

interface SpendStepProps {}

export const SpendStep: FC<SpendStepProps> = () => {
  return (
    <Step
      heading="Reliable retirement income with one easy, safe-to-spend paycheck"
      body="We'll review your savings, Social Security and other income sources, to help your personalized Safe-to-spend number each month."
      image={<StaticImage src="../images/spend-step.webp" alt="spend" />}
    />
  );
}
