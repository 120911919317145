import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface WordSelectorProps {
    options: string[];
    value: string;
    onChange: (value: string) => void;
}

const StyledSelect = styled.select`
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: right;
    border-bottom: 1px solid ${colors.black};
    padding-right: 16px;
`;

export const WordSelector: FC<WordSelectorProps> = ({ options, value, onChange }) => (
    <StyledSelect value={value} onChange={(e) => onChange(e.target.value)}>
        {options.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
        ))}
    </StyledSelect>
);
